import React, { useEffect, useState } from "react";
import { get, isEmpty } from "lodash";
import { withRouter } from "react-router-dom";
import { Input, DatePicker, Select, Button } from "antd";
import * as classes from "../Table.module.css";
import { SearchOutlined } from "@ant-design/icons";
import { isMobile } from "react-device-detect";
import orderAPI from "../../api/OrderAPI/orderAPI";
import eventAPI from "../../api/EventAPI/eventAPI";
import Pagination from "react-js-pagination";
import { getCurrentUser } from "../../util/auth";
import Layout from "../../components/Layout/Layout";
import { checkPermission } from "../../util/helpers";
import Loader from "../../components/Loader/Loader";
import PermissionDeniedModal from "../../components/PermissionDeniedModal/PermissionDeniedModal";
import TextModal from "../../components/TextModal/TextModal";
import { CSVLink } from "react-csv";

const { RangePicker } = DatePicker;
const { Option } = Select;
const object = "list";
const Orders = (props) => {
  const { location } = props;
  const { search } = location;

  const query = new URLSearchParams(search);
  const eventIdQuery = query.get('eventId') || '';
  const pageQuery = query.get('page') || 1;

  const [eventData, setEventData] = useState({
    eventList: [],
    selectedEventId: eventIdQuery
  });

  const [orderData, setOrderData] = useState({
    orderList: [],
    paging: null
  });

  const [searchData, setSearchData] = useState({
    type: 'order_code',
    value: ''
  });

  const [filterData, setFilterData] = useState({
    order_code: '',
    buyer_phone: '',
    buyer_name: '',
    buyer_email: '',
    payment_method: '',
    is_vat: '',
    order_status: '',
    from_date: '',
    to_date: ''
  });

  const [isShowNoDataModal, setIsShowNoDataModal] = useState(false);
  const [isShowPermissionModal, setIsShowPermissionModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [couponCodesUsedData, setCouponCodesUsedData] = useState([]);
  const [publicOrderData, setPublicOrderData] = useState([]);

  const user = getCurrentUser();

  useEffect(() => {
    const isPermission = checkPermission(user, object);
    if (isPermission) {
      getUserAllowedEvents();
    } else {
      setIsShowPermissionModal(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isEmpty(eventData.eventList)) {
      const id = eventIdQuery || eventData.eventList[0].id;
      getOrders(pageQuery, id);
      getCouponCodesUsed(id);
      getPublicOrderData(id);
    }
  }, [eventData.eventList]) // eslint-disable-line react-hooks/exhaustive-deps

  const getUserAllowedEvents = async () => {
    const userAllowedEventIds = get(user, 'result.role_extra.event_ids', '');
    const userAllowedMerchantIds = get(user, 'result.role_extra.merchant_ids', '');

    const userAllowedEventIdsArr = get(user, 'result.role_extra.event_ids', '').split(',');
    const userAllowedMerchantIdsArr = get(user, 'result.role_extra.merchant_ids', '').split(',');

    let eventResult = [];

    try {
      if (userAllowedMerchantIds === 'all') { // Show all events from all mechants
        const eventResponse = await eventAPI.getEvents();
        if (!isEmpty(eventResponse)) {
          if (userAllowedEventIds === 'all' || isEmpty(userAllowedEventIds)) {
            eventResult = eventResponse;
          } else {
            eventResult = eventResponse.filter(event => userAllowedEventIdsArr.includes(event.id));
          }
        }
      } else { // Show events based on user merchants
        await Promise.all(userAllowedMerchantIdsArr.map(async merchantId => { // Loop mechants to get merchant's events
          const eventByMerchantIdResponse = await eventAPI.getEventsByMerchant(merchantId);
          if (!isEmpty(eventByMerchantIdResponse)) {
            eventResult = [...eventResult, ...eventByMerchantIdResponse]
          }
        }));
        if (userAllowedEventIds === 'all' || isEmpty(userAllowedEventIds)) {
          //
        } else {
          eventResult = eventResult.filter(event => userAllowedEventIdsArr.includes(event.id));
        }
      }

      setEventData({
        eventList: eventResult,
        selectedEventId: !isEmpty(eventIdQuery) ? eventIdQuery : eventResult[0].id
      });

    } catch (error) {
      console.log("Faile to fetch", error);
    }
  }

  const getOrders = async (page, eventId) => {
    const submitFilterData = { ...filterData };
    if (!isEmpty(searchData.type) && !isEmpty(searchData.value)) submitFilterData[searchData.type] = searchData.value.replace(/[#]/g, '');

    try {
      const response = await orderAPI.getOrders(page, eventId, submitFilterData);
      if (response) {
        setOrderData({
          orderList: response.data,
          paging: response.paging
        })
        window.scrollTo(0, 0);
      }
    } catch (error) {
      setIsShowNoDataModal(true);
      setOrderData({
        orderList: [],
        paging: null
      });
      console.log("Faile to fetch", error);
    }
  };

  const getCouponCodesUsed = async (eventId) => {
    try {
      const response = await orderAPI.getCouponCodesUsed(eventId);
      if (response) {
        setCouponCodesUsedData(response.result);
      }
    } catch (error) {
      setCouponCodesUsedData([]);
      console.log("Faile to fetch", error);
    }
  };

  const getPublicOrderData = async (eventId) => {
    const submitFilterData = { ...filterData };
    if (!isEmpty(searchData.type) && !isEmpty(searchData.value)) submitFilterData[searchData.type] = searchData.value.replace(/[#]/g, '');

    try {
      const response = await orderAPI.getPublicOrdersExportedData(eventId, submitFilterData);
      if (response) {
        response.forEach((item) => {
          const method = transformPaymentMethod(item.payment_method);

          item.payment_method = method;
        });

        setPublicOrderData(response);
      }
    } catch (error) {
      setPublicOrderData([]);
      console.log("Faile to fetch", error);
    }
  };

  const _handleSearchDataChange = (key, value) => {
    setSearchData({
      ...searchData,
      [key]: value
    })
  }

  const _handleFilterDataChange = (updatedData) => {
    setFilterData({
      ...filterData,
      ...updatedData
    })
  }

  const _handleEventChange = (value) => {
    const updatedEventData = { ...eventData };
    updatedEventData.selectedEventId = value;
    setEventData(updatedEventData);
  }

  const _handleSubmitFilter = async () => {
    setIsLoading(true);
    await getOrders(1, eventData.selectedEventId);
    await getCouponCodesUsed(eventData.selectedEventId);
    await getPublicOrderData(eventData.selectedEventId);
    setIsLoading(false);
  }

  const _handleDateChange = async (value) => {
    if (value === null) {
      return _handleFilterDataChange({
        fromDate: '',
        toDate: ''
      })
    };
    const fromDateChosen = value[0].format("YYYY-MM-DD");
    const toDateChosen = value[1].format("YYYY-MM-DD");

    _handleFilterDataChange({
      from_date: fromDateChosen,
      to_date: toDateChosen
    });
  }

  const _handlePageChange = (page) => {
    getOrders(page, eventData.selectedEventId)
  }

  const colorOrderStatus = (status) => {
    switch (status) {
      case "doi_thanh_toan":
        return "#1890ff";
      case "dat_hang_thanh_cong":
        return "#52c41a";
      case "hoan_thanh":
        return "#5b8c00";
      default:
        return "#000000";
    }
  };

  const searchTypeString = (type) => {
    switch (type) {
      case "order_code":
        return "Order Code";
      case "buyer_phone":
        return "Phone Number";
      case "buyer_name":
        return "Name";
      case "buyer_email":
        return "Email";
      default:
        return "#000000";
    }
  }

  const navigateOrderDetail = (code) => {
    props.history.push(`/orders/${code}`);
  };

  const transformPaymentMethod = (method) => {
    switch (method) {
      case 'credit':
        return "Thẻ tín dụng (Credit)";
      case 'credit_global':
        return "Thẻ tín dụng phát hành ngoài VN";
      case 'atm':
        return "Thẻ ngân hàng (ATM)";
      case 'zalopay':
        return 'ZaloPay';
      case 'viet_qr':
        return 'Viet QR';
      case 'vnpay':
        return 'QRCode';
      case 'bank_transfer':
        return 'Chuyển khoản ngân hàng';
      case 'voucher':
        return 'Voucher';
      case 'momo':
        return 'Momo';
      case 'partner_vnpay':
        return 'Partner Vnpay';
      case 'vn_pay_b':
        return 'VNPAY';
      default:
        return "Thẻ tín dụng (Credit)"
    }
  }

  return (
    <Layout>
      <div>
        <Select
          showSearch
          style={{
            width: isMobile ? "100%" : 300,
            marginRight: isMobile ? 0 : 6,
            marginBottom: 5
          }}
          value={get(eventData, 'selectedEventId', '')}
          onChange={(e) => _handleEventChange(e)}
          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
          optionFilterProp={(optionA, optionB) =>
            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
          }
        >
          {eventData.eventList.map((event) => {
            return (
              <Option key={event.id} value={event.id}>
                {event.name_en}
              </Option>
            );
          })}
        </Select>
        <Input.Group className={isMobile ? "d-flex justify-content-between align-items-center w-100 p-0" : ''}>
          <Select
            style={{
              width: isMobile ? "49%" : 300,
              marginRight: isMobile ? 0 : 6,
              marginBottom: 5,
              float: 'left'
            }}
            placeholder="Search Type"
            value={searchData.type || null}
            onChange={(type) => _handleSearchDataChange('type', type)}
          >
            <Option value="order_code">{searchTypeString('order_code')}</Option>
            <Option value="buyer_phone">{searchTypeString('buyer_phone')}</Option>
            <Option value="buyer_name">{searchTypeString('buyer_name')}</Option>
            <Option value="buyer_email">{searchTypeString('buyer_email')}</Option>
          </Select>
          <Input
            style={{
              width: isMobile ? "49%" : 300,
              marginRight: isMobile ? 0 : 6,
              marginBottom: 5
            }}

            type="text"
            name="search"
            placeholder={`Search For ${searchTypeString(searchData.type)}`}
            value={searchData.value}
            onChange={(e) => _handleSearchDataChange('value', e.target.value)}
          />
        </Input.Group>
        <Select
          style={{
            width: isMobile ? "100%" : 300,
            marginRight: isMobile ? 0 : 6,
            marginBottom: 5
          }}
          placeholder="Payment Method"
          value={filterData.payment_method || null}
          onChange={(method) => _handleFilterDataChange({
            payment_method: method
          })}
        >
          <Option value="credit">Credit</Option>
          <Option value="atm">ATM</Option>
          <Option value="bank_transfer">Bank Transfer</Option>
          <Option value="credit_global">Credit issued outside Vietnam</Option>
          <Option value="zalopay">Zalopay</Option>
          <Option value="viet_qr">Viet QR</Option>
          <Option value="vnpay">QR Code</Option>
          <Option value="voucher">Voucher</Option>
          <Option value="partner_vnpay">Partner Vnpay</Option>
          <Option value="momo">Momo</Option>
          <Option value="vn_pay_b">VNPAY</Option>
        </Select>
        <Select
          style={{
            width: isMobile ? "100%" : 300,
            marginRight: isMobile ? 0 : 6,
            marginBottom: 5
          }}

          placeholder="VAT"
          value={filterData.is_vat || null}
          onChange={(isVAT) => _handleFilterDataChange({
            is_vat: isVAT
          })}
        >
          <Option value="1">Yes</Option>
          <Option value="0">No </Option>
        </Select>

        <Select
          style={{
            width: isMobile ? "100%" : 300,
            marginRight: isMobile ? 0 : 6,
            marginBottom: 5
          }}
          placeholder="Order Status"
          value={filterData.order_status || null}
          onChange={(orderStatus) => _handleFilterDataChange({
            order_status: orderStatus
          })}
        >
          <Option key="doi" value="doi_thanh_toan">
            Waiting Payment
          </Option>
          <Option value="dat_hang_thanh_cong">Order Success</Option>
          <Option value="da_huy">Order Canceled</Option>
          <Option value="hoan_tien">Refund</Option>
          <Option value="cho_hoan_tien">Waiting Refund</Option>
        </Select>
        <RangePicker
          style={{
            width: isMobile ? "100%" : 300,
            marginRight: isMobile ? 0 : 6,
            marginBottom: 5
          }}
          bordered={true}
          onChange={(dateValue) => _handleDateChange(dateValue)}
        />
        {!isEmpty(eventData.eventList) ? (
          <Button
            // className={classes.search}
            style={{
              marginBottom: 5
            }}
            type="primary"
            icon={<SearchOutlined />}
            onClick={_handleSubmitFilter}
          >
            Search
          </Button>
        ) : null}
        {!isEmpty(couponCodesUsedData) ? (
          <Button
            // className={classes.search}
            style={{
              marginBottom: 5,
              marginLeft: 5,
              backgroundColor: '#011bb6'
            }}
            type="primary"
            onClick={() => { }}
          >
            <CSVLink
              data={couponCodesUsedData}
              filename={"used-codes.csv"}
            >
              Export used code
            </CSVLink>
          </Button>
        ) : null}
        {/* {!isEmpty(publicOrderData) ? (
          <Button
            // className={classes.search}
            style={{
              marginBottom: 5,
              marginLeft: 5,
              backgroundColor: '#011bb6'
            }}
            type="primary"
            onClick={() => { }}
          >
            <CSVLink
              data={publicOrderData}
              filename={"public-orders.csv"}
            >
              Export orders
            </CSVLink>
          </Button>
        ) : null} */}

        {
          (publicOrderData && publicOrderData.length > 0) &&
          <Button
            // className={classes.search}
            style={{
              marginBottom: 5,
              marginLeft: 5,
              backgroundColor: '#011bb6'
            }}
            type="primary"
            onClick={() => { }}
          >
            <CSVLink
              data={publicOrderData}
              filename={"public-orders.csv"}
            >
              Export orders
            </CSVLink>
          </Button>
        }


      </div>
      <div className="table-responsive mt-4">
        <table className="table table-hover">
          <tbody>
            <tr className={classes.backgroundTr}>
              <th scope="col" className={classes.backgroundTh}>
                Order Code
              </th>
              <th scope="col" className={classes.backgroundTh}>
                Phone / Email
              </th>
              <th scope="col" className={classes.backgroundTh}>
                Product
              </th>
              <th scope="col" className={classes.backgroundTh}>
                Event
              </th>
              <th scope="col" className={classes.backgroundTh}>
                VAT
              </th>
              <th scope="col" className={classes.backgroundTh}>
                Payment Method
              </th>

              <th scope="col" className={classes.backgroundTh}>
                Order Status
              </th>
              <th scope="col" className={classes.backgroundTh}>
                Date
              </th>
              <th scope="col" className={classes.backgroundTh}>
                E-ticket usage date
              </th>
            </tr>
            {orderData.orderList.map((order) => {
              return (
                <tr key={order.code}>
                  <td className={classes.backgroundTd}>
                    <span
                      style={{
                        color: "#1794fc",
                        fontWeight: "500",
                        cursor: "pointer",
                      }}
                      onClick={() => navigateOrderDetail(order.code)}
                    >
                      {order.code}
                    </span>
                  </td>
                  <td className={classes.backgroundTd}>
                    <span> {get(order, 'buyer.phone_area_code', '') && `(+${get(order, 'buyer.phone_area_code', '')})`} {order.buyer.phone} </span>
                    <br></br>
                    <span> {order.buyer.email} </span>
                  </td>
                  <td className={classes.backgroundTd}>
                    <span> {order.item_description_vi} </span>
                  </td>
                  <td className={classes.backgroundTd}>
                    <span> {order.extra.event_name} </span>
                  </td>
                  <td className={classes.backgroundTd}>
                    {order.is_vat === "1" ? (
                      <div>
                        <span style={{ color: "green" }}> Có </span>
                      </div>
                    ) : (
                      <div>
                        <span style={{ color: "red" }}> Không </span>
                      </div>
                    )}
                  </td>
                  <td className={classes.backgroundTd}>
                    {/* <span style={{ textTransform: "capitalize" }}>
                      {" "}
                      {get(
                        order,
                        "extra.checkout_data.payment_method",
                        ""
                      ).replace("_", " ")}{" "}
                    </span> */}
                    {
                      order && order.extra && order.extra.checkout_data && order.extra.checkout_data.payment_method && order.extra.checkout_data.payment_method !== '' &&
                      <span style={{ textTransform: "capitalize" }}>
                        {transformPaymentMethod(order.extra.checkout_data.payment_method)}
                      </span>
                    }

                  </td>
                  <td
                    className={classes.backgroundTd}
                    style={{ color: `${colorOrderStatus(order.order_status)}` }}
                  >
                    <span> {order.order_status_description_vi} </span>
                  </td>
                  <td className={classes.backgroundTd}>
                    <span> {order.created_at} </span>
                  </td>
                  <td className={classes.backgroundTd}>
                    <span> {order.ticket_use_for_date} </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className={classes.pagination}>
        {orderData.paging !== null &&
          orderData.paging.total_item >= 30 ? (
          <Pagination
            hideFirstLastPages
            pageRangeDisplayed={7}
            activePage={orderData.paging.current_page}
            itemsCountPerPage={30}
            totalItemsCount={orderData.paging.total_item}
            onChange={_handlePageChange}
            itemClass="page-item"
            linkClass="page-link"
          />
        ) : null}
      </div>
      <Loader isOpen={isLoading} />
      <PermissionDeniedModal
        isOpen={isShowPermissionModal}
        toggle={() => setIsShowPermissionModal(!isShowPermissionModal)}
      />
      <TextModal
        isOpen={isShowNoDataModal}
        toggle={() => setIsShowNoDataModal(!isShowNoDataModal)}
        bodyText={'No Data'}
      />
    </Layout>
  );
}

export default withRouter(Orders);
